/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "../../config/config";
import { IntegratedSelection, SelectionState } from "@devexpress/dx-react-grid";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import swal from "sweetalert";


export function MyPage5(props) {
  const user = useSelector((state) => state.auth.user);
  //const {intl} = props;
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  let cazz = [];

  useEffect(() => {
    GetData();
  }, []);

  function GetData() {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "GET"
    };

    fetch(config.apiUrl + "order/closeWorkDayBrt.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading(true);
        } else {
          setLoading(false);
          setDataOrder(result);
        }
      });
  }

  dataOrder.map((element) => {
    return cazz.push(element);
  });

  const [sorting, setSorting] = useState([{ columnName: "date_created", direction: "asc" }]);
  const [selection, setSelection] = useState([]);
  let dataSelected = dataOrder.filter((el, key) => selection.includes(key));
  const [columns] = useState([
    { name: "id_parcella", title: "id Parcella" },
    { name: "referenza_corriere", title: "Referenza corriere" },
    { name: "parcell_id_brt", title: "Id Parcella BRT" },
    { name: "date_created", title: "Data Creazione" }
  ]);

  const [defaultColumnWidths] = useState([
    { columnName: "id_parcella", width: 200 },
    { columnName: "referenza_corriere", width: 200 },
    { columnName: "parcell_id_brt", width: 200 },
    { columnName: "date_created", width: 200 }
  ]);


  return (
    <>
      <div className="container">
        <div className="row">
         <span className="font-weight-bold">
           Total rows selected:
           {"  "}
           {selection.length}
        </span>
        </div>
      </div>
      <div className="card">
        <Grid
          rows={cazz}
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={15}
          />
          <IntegratedPaging />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <IntegratedSorting />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <Table />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
          />
          <TableHeaderRow />
          <IntegratedSelection />
          <TableSelection
            showSelectAll
          />
          <PagingPanel />
        </Grid>
      </div>
    </>
  );
}
