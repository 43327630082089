/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
//import Table from "react-bootstrap/Table";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import config from "../../config/config";
import { SelectionState } from "@devexpress/dx-react-grid";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import swal from "sweetalert";


export function MyPage11() {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  const [dataOrder2, setDataOrder2] = useState([]);
  const [dataOrder3, setDataOrder3] = useState([]);


  useEffect(() => {
    getData();
    getData2();
    getData3();
  }, []);

  function getData() {

    const requestOptions = {
      headers: {
        Authorization: "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      }, method: "GET"
    };

    fetch(config.apiUrl + "order/dispositivo.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          console.log("fuori");
        } else {
          setDataOrder(result);
        }
      });
  }

  function getData2() {

    const requestOptions = {
      headers: {
        Authorization: "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      }, method: "GET"
    };

    fetch(config.apiUrl + "order/dispositivo2.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          console.log("fuori");
        } else {

          console.log(result);
          setDataOrder2(result);
        }
      });
  }

  function getData3() {

    const requestOptions = {
      headers: {
        Authorization: "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      }, method: "GET"
    };

    fetch(config.apiUrl + "order/dispositivo3.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          console.log("fuori");
        } else {

          console.log(result);
          setDataOrder3(result);
        }
      });
  }


  const [sorting, setSorting] = useState([{ columnName: "product_reference", direction: "asc" }]);


  const [columns] = useState([
    { name: "id_ordine", title: "id Order" },
    { name: "name", title: "Nome" },
    { name: "prodotto", title: "Prodotto" },
    { name: "quantita", title: "Quantità" },
    { name: "ref_corriere", title: "ref_corriere" },
    { name: "ref_prodotto", title: "ref_prodotto" },
    { name: "referenza", title: "referenza" },
    { name: "user_ref", title: "user_ref" }
  ]);

  const [defaultColumnWidths] = useState([
    { columnName: "id_ordine", width: 200 },
    { columnName: "name", width: 200 },
    { columnName: "prodotto", width: 200 },
    { columnName: "quantita", width: 50 },
    { columnName: "ref_corriere", width: 100 },
    { columnName: "ref_prodotto", width: 100 },
    { columnName: "referenza", width: 100 },
    { columnName: "user_ref", width: 50 }
  ]);

  let cazz = [];
  let cazz2 = [];

  if (user.id === 13) {

    if (dataOrder !== null) {
      dataOrder.map((element) => {
        return cazz.push(element);
      });
    } else {
      return (
        <>
          <h3 className="text-center">No Data</h3>
        </>
      );
    }

    return (
      <>
        <div className="card">
          <>
            <Grid
              rows={cazz}
              columns={columns}
            >
              <PagingState
                defaultCurrentPage={0}
                pageSize={15}
              />
              <IntegratedPaging />
              <SortingState
                sorting={sorting}
                onSortingChange={setSorting}
              />
              <IntegratedSorting />
              <Table />
              <TableColumnResizing
                defaultColumnWidths={defaultColumnWidths}
              />
              <TableHeaderRow />
              <PagingPanel />
            </Grid>
          </>
        </div>
      </>
    );
  } else if (user.id === 14) {
    if (dataOrder !== null) {
      dataOrder2.map((element) => {
        return cazz2.push(element);
      });
    } else if (user.id === 15) {
      if (dataOrder !== null) {
        dataOrder3.map((element) => {
          return cazz2.push(element);
        });
      } else {
        return (
          <>
            <h3 className="text-center">No Data</h3>
          </>
        );
      }
    }


    return (
      <>
        <div className="card">
          <Grid
            rows={cazz2}
            columns={columns}
          >
            <PagingState
              defaultCurrentPage={0}
              pageSize={15}
            />
            <IntegratedPaging />
            <SortingState
              sorting={sorting}
              onSortingChange={setSorting}
            />
            <IntegratedSorting />
            <Table />
            <TableColumnResizing
              defaultColumnWidths={defaultColumnWidths}
            />
            <TableHeaderRow />
            <PagingPanel />
          </Grid>
        </div>
      </>
    );
  }
}
