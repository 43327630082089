/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  console.log(user.private);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  console.log(user);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        
        {parseInt(user.role) === 1 ? (<>
          <li className="menu-section ">
            <h4 className="menu-text">Amministrazione</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* Custom Page */}
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/product",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/product">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag1.svg")} />
            </span>
              <span className="menu-text">Prodotti</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/brtorder",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/brtorder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
              <span className="menu-text">Ordini BRT</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/brtconferm",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/brtconferm">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
            </span>
              <span className="menu-text">Corferma BRT</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/closeworkdaybrt",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/closeworkdaybrt">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
            </span>
              <span className="menu-text">CloseWorkDay Brt</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/order",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/order">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
              <span className="menu-text">Ordini GLS</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/glsDayClose",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/glsDayClose">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
            </span>
              <span className="menu-text">Closeworkday GLS</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/createorder",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/createorder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-read.svg")} />
            </span>
              <span className="menu-text">Crea Ordine GLS</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/zplgls",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/zplgls">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
              <span className="menu-text">Zpl Gls</span>
            </NavLink>
          </li>
  
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/adduser",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/adduser">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
              <span className="menu-text">Aggiungi utente</span>
            </NavLink>
          </li>

  


          <li className="menu-section ">
            <h4 className="menu-text">Magazzino</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/orderwharehausegls",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/orderwharehausegls">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-read.svg")} />
            </span>
              <span className="menu-text">Ordini Magazzino Gls</span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/orderwharehausebrt",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/orderwharehausebrt">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-read.svg")} />
            </span>
              <span className="menu-text">Ordini Magazzino Brt</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/ordertosend",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/ordertosend">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")} />
            </span>
              <span className="menu-text">Genera Ordinamento Ordini Gls</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/ordertosendbrt",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/ordertosendbrt">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")} />
            </span>
              <span className="menu-text">Genera Ordinamento Ordini Brt</span>
            </NavLink>
          </li>
        </>) : (<>

          <li className="menu-section ">
            <h4 className="menu-text">Magazzino</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/orderwharehausegls",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/orderwharehausegls">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
              <span className="menu-text">Ordini Magazzino Gls</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/orderwharehausebrt",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/orderwharehausebrt">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
              <span className="menu-text">Ordini Magazzino Brt</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/ordertosend",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/ordertosend">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
              <span className="menu-text">Genera Ordinamento Ordini Gls</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/ordertosendbrt",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/ordertosendbrt">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
              <span className="menu-text">Genera Ordinamento Ordini Brt</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/dispositivo",
              false
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/dispositivo">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
              <span className="menu-text">{user.name}</span>
            </NavLink>
          </li>
        </>)}


      </ul>

      {/* end::Menu Nav */}
      
    </>
  );
}
