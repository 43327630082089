/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import config from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";

const initialValues = {
  nominativo: "",
  provincia: "",
  localita: "",
  indirizzo: "",
  zipCode: "",
  sede: "",
  siglaSede: "",
  zona: "",
  colli: "",
  peso: "",
  volume: "",
  colleType: "Normale",
  contrassegno: "",
  contmoney:"",
  servAcc: "31-FLEX DELIVERY",
  refEt: "",
  note: "",
  noteAutista: "",
  notifica: "",
  notificaTel: "",
  bda: null,
  assicurazione: ""
};

export function MyPage7(props) {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const RegistrationSchema = Yup.object().shape({
    nominativo: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Inserisci un nominativo"),
    provincia: Yup.string()
      .max(2, "Maximum 2 symbols")
      .required("Inserisci una provincia"),
    localita: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Inserisci una località"),
    indirizzo: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("inserisci un indirizzo"),
    zipCode: Yup.string()
      .max(5, "Maximum 5 symbols")
      .required("Iniserisci uno zip code"),
    sede: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Inserisci una Sede"),
    siglaSede: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Insierisci una Sigla Sede"),
    zona: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Inserisci una zona"),
    colli: Yup.string()
      .min(1, "Minimum 1 symbols")
      .max(50, "Maximum 50 symbols")
      .required("inserisci un valore al colli"),
    peso: Yup.string()
      .required("inserisci un peso in KG"),
    volume: Yup.string()
      .required("Inserisci un peso volume"),
    contrassegno: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("inseisci se contrassegno!"),
    refEt: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Inserisci un riferimento"),
    note: Yup.string()
      .min(2, "Minimum 2 symbols")
      .required("Inserisci una nota"),
    noteAutista: Yup.string()
      .min(2, "Minimum 2 symbols")
      .required("Inserisci una nota autista"),
    notifica: Yup.string()
      .min(2, "Minimum 2 symbols")
      .required("inserisci una notifica"),
    notificaTel: Yup.string()
      .min(2, "Minimum 2 symbols")
      .required("Inserisci una notifica telefono"),
    bda: Yup.number()
      .min(1, "Minimum 1 symbols")
      .required("Inserisci un BDA/DDT"),
    assicurazione: Yup.string()
      .min(2, "Minimum 2 symbols")
      .required("Inserisci se ha assicurazione")
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      setLoading(true);
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
        },
        method: "POST",
        body: JSON.stringify({
          nominativo: values.nominativo,
          provincia: values.provincia,
          localita: values.localita,
          indirizzo: values.indirizzo,
          zipCode: values.zipCode,
          sede: values.sede,
          siglaSede: values.siglaSede,
          zona: values.zona,
          colli: values.colli,
          peso: values.peso,
          volume: values.volume,
          colleType: values.colleType,
          contrassegno: values.contrassegno,
          contmoney:values.contmoney,
          servAcc: values.servAcc,
          refEt: values.required,
          note: values.note,
          noteAutista: values.noteAutista,
          notifica: values.notifica,
          notificaTel: values.notificaTel,
          bda: values.bda,
          assicurazione: values.assicurazione
        })
      };
      fetch(config.apiUrl + "order/CreateParcellGls.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          swal({
            title: "Ordine creato!",
            text: "OK",
            icon: "success",
            type: "Success"
          }
          ).then(function () {
            window.location.reload();
          });
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
        });
    }
  });

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
      >
        <div className="container mb-3">
          <div className="row mt-2 mb-5">
            <div className="col text-center">
              <h4>Destinatario</h4>
            </div>
          </div>
          <div className="container mt-3 mb-3">
            <div className="row ">
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Nominativo</label>
                <input
                  placeholder="Nominativo"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "nominativo"
                  )}`}
                  name="nominativo"
                  {...formik.getFieldProps("nominativo")}
                />
                {formik.touched.nominativo && formik.errors.nominativo ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.nominativo}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Provincia</label>
                <input
                  placeholder="Prov"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "provincia"
                  )}`}
                  name="provincia"
                  {...formik.getFieldProps("provincia")}
                />
                {formik.touched.provincia && formik.errors.provincia ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.provincia}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Località</label>
                <input
                  placeholder="Località"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "localita"
                  )}`}
                  name="localita"
                  {...formik.getFieldProps("localita")}
                />
                {formik.touched.localita && formik.errors.localita ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.localita}</div>
                  </div>
                ) : null}
              </div>

              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Indirizzo</label>
                <input
                  placeholder="Indirizzo"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "indirizzo"
                  )}`}
                  name="indirizzo"
                  {...formik.getFieldProps("indirizzo")}
                />
                {formik.touched.indirizzo && formik.errors.indirizzo ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.indirizzo}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="container mt-3 mb-3">
            <div className="row ">
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">ZipCode</label>
                <input
                  placeholder="ZipCode"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "zipCode"
                  )}`}
                  name="zipcode"
                  {...formik.getFieldProps("zipCode")}
                />
                {formik.touched.zipCode && formik.errors.zipCode ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.zipCode}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Sede</label>
                <input
                  placeholder="Sede"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "sede"
                  )}`}
                  name="sede"
                  {...formik.getFieldProps("sede")}
                />
                {formik.touched.sede && formik.errors.sede ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.sede}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Sigla sede</label>
                <input
                  placeholder="siglaSede"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "siglaSede"
                  )}`}
                  name="siglaSede"
                  {...formik.getFieldProps("siglaSede")}
                />
                {formik.touched.siglaSede && formik.errors.siglaSede ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.siglaSede}</div>
                  </div>
                ) : null}
              </div>

              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Zona</label>
                <input
                  placeholder="zona"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "zona"
                  )}`}
                  name="zona"
                  {...formik.getFieldProps("zona")}
                />
                {formik.touched.zona && formik.errors.zona ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.zona}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="container mb-3">
            <div className="row mt-2 mb-5">
              <div className="col text-center">
                <h4>Dati spedizione</h4>
              </div>
            </div>
          </div>
          <div className="container mt-3 mb-3">
            <div className="row ">
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Colli</label>
                <input
                  placeholder="Colli"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "colli"
                  )}`}
                  name="colli"
                  {...formik.getFieldProps("colli")}
                />
                {formik.touched.colli && formik.errors.colli ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.colli}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Peso reale al Kg.</label>
                <input
                  placeholder="Peso"
                  type="number"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "peso"
                  )}`}
                  name="peso"
                  {...formik.getFieldProps("peso")}
                />
                {formik.touched.peso && formik.errors.peso ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.peso}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Peso volume</label>
                <input
                  placeholder="Volume"
                  type="number"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "volume"
                  )}`}
                  name="volume"
                  {...formik.getFieldProps("volume")}
                />
                {formik.touched.volume && formik.errors.volume ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.volume}</div>
                  </div>
                ) : null}
              </div>

              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Tipo Colle</label>
                <input
                  placeholder="colleType"
                  disabled="true"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "colleType"
                  )}`}
                  name="colleType"
                  {...formik.getFieldProps("colleType")}
                />
                {formik.touched.colleType && formik.errors.colleType ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.colleType}</div>
                  </div>
                ) : null}
              </div>
            
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Contrassegno </label>
                <select
                   placeholder="Contrassegno"
                   type="text"
                   disabled=''
                   className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                     "contrassegno"
                   )}`}
                   name="contrassegno"
                   {...formik.getFieldProps("contrassegno")}
                >
                  <option value="no" default>NO</option>       
                  <option value="CONT">Si</option>
                </select>
                {formik.touched.contrassegno && formik.errors.contrassegno ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.contrassegno}</div>
                  </div>
                ) : null}
              </div>
              {
                formik.values.contrassegno === 'CONT' ? (
                  <>
                  <div className="col text-center">
                <label htmlFor="exampleInputPassword1"> Importo €</label>
                <input
                   placeholder="contmoney"
                   type="number"
                   disabled=''
                   className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                     "contmoney"
                   )}`}
                   name="contmoney"
                   {...formik.getFieldProps("contmoney")}
                />
                {formik.touched.contmoney && formik.errors.contmoney ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.contmoney}</div>
                  </div>
                ) : null}
              </div>
                  </>
                ) : 
                (
                <>
                </>) 
              }
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Servizi accessori</label>

                <select
                  placeholder=""
                  //disabled="true"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "servAcc"
                  )}`}
                  name="servAcc"
                  {...formik.getFieldProps("servAcc")}
                  // aria-label=".form-select-sm example"
                  id="servAcc"
                ><option value="02">02-ORA FISSA</option>
                  <option value="22">22-EXPRESS12</option>
                  <option value="03">03-ANTICIPATO</option>
                  <option value="05">05-CONSEGNA AL PIANO</option>
                  <option value="06">06-MEZZO IDONEO</option>
                  <option value="36">36-SATURDAY EXPRESS</option>
                  <option value="07">07-SERVIZIO AL SABATO</option>
                  <option value="08">08-VERIFICA CONTENUTO</option>
                  <option value="14">14-ENTRO ORE 13 VENEZIA LAGUNA</option>
                  <option value="15">15-MEZZO IDONEO VENEZIA LAGUNA</option>
                  <option value="16">16-SCALO FLUVIALE VENEZIA</option>
                  <option value="21">21-SERVIZIO AL SABATO VENEZIA</option>
                  <option value="01">01-ENTRO ORE 12</option>
                  <option value="23">23-DOCUMENT RETURN</option>
                  <option value="24">24-EXCHANGE</option>
                  <option value="25">25-PREAVVISO TELEFONICO</option>
                  <option value="27">27-TIMBRO</option>
                  <option value="28">28-E-ROD</option>
                  <option value="30">30-GDO</option>
                  <option value="31">31-FLEX DELIVERY</option>
                  <option value="32">32-IDENT PIN</option>
                  <option value="34">34-GDO (ENTRO IL)</option>
                  <option value="35">35-FOOD SERVICE</option>
                  <option value="38">38-HEALTHCARE</option>
                </select>
                {formik.touched.servAcc && formik.errors.servAcc ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.servAcc}</div>
                  </div>
                ) : null}
              </div>
              
            </div>
          </div>
          <div className="container mt-3 mb-3">
            <div className="row ">
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Riferimenti etichetta</label>
                <input
                  placeholder="Riferimento etichetta"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "refEt"
                  )}`}
                  name="refEt"
                  {...formik.getFieldProps("refEt")}
                />
                {formik.touched.refEt && formik.errors.refEt ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.refEt}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Note</label>
                <textarea
                  placeholder="Note"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "note"
                  )}`}
                  name="note"
                  {...formik.getFieldProps("note")}
                />
                {formik.touched.note && formik.errors.note ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.note}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="container mt-3 mb-3">
            <div className="row ">
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Note autista</label>
                <textarea
                  placeholder="Note autista"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "noteAutista"
                  )}`}
                  name="noteAutista"
                  {...formik.getFieldProps("noteAutista")}
                />
                {formik.touched.noteAutista && formik.errors.noteAutista ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.noteAutista}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Indirizzi Email x notifica</label>
                <input
                  placeholder="Indirizzi Email x notifica"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "notifica"
                  )}`}
                  name="notifica"
                  {...formik.getFieldProps("notifica")}
                />
                {formik.touched.notifica && formik.errors.notifica ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.notifica}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="container mt-3 mb-3">
            <div className="row ">
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Num per notifiche SMS e/o TELEFONICHE</label>
                <input
                  placeholder="Num per notifiche SMS e/o TELEFONICHE (Nazionale)"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "notificaTel"
                  )}`}
                  name="notificaTel"
                  {...formik.getFieldProps("notificaTel")}
                />
                {formik.touched.notificaTel && formik.errors.notificaTel ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.notificaTel}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">BDA/DDT</label>
                <input
                  placeholder="BDA/DDT"
                  type="number"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "bda"
                  )}`}
                  name="bda"
                  {...formik.getFieldProps("bda")}
                />
                {formik.touched.bda && formik.errors.bda ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.bda}</div>
                  </div>
                ) : null}
              </div>
              <div className="col text-center">
                <label htmlFor="exampleInputPassword1">Importo Assicurazione (€)</label>
                <input
                  placeholder="Importo Assicurazione (€)"
                  type="text"
                  className={`form-control form-control h-auto py-5 px-6 ${getInputClasses(
                    "assicurazione"
                  )}`}
                  name="assicurazione"
                  {...formik.getFieldProps("assicurazione")}
                />
                {formik.touched.assicurazione && formik.errors.assicurazione ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.assicurazione}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              style={{ background: "#2f2d77", color: "#ffffff" }}
            >
              <span>Crea ordine</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
