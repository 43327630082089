/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
//import Table from "react-bootstrap/Table";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import config from "../../config/config";
import { IntegratedSelection, SelectionState } from "@devexpress/dx-react-grid";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import swal from "sweetalert";


export function MyPage10() {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  let cazz = [];
  useEffect(() => {
    GetData();
  }, []);

  function GetData() {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "GET"
    };

    fetch(config.apiUrl + "order/getForConfermToBrt.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading(true);
        } else {
          setLoading(false);
          setDataOrder(result);
        }
      });
  }

  dataOrder.map((element) => {
    return cazz.push(element);
  });


  const [sorting, setSorting] = useState([{ columnName: "product_reference", direction: "asc" }]);
  const [selection, setSelection] = useState([]);
  let dataSelected = dataOrder.filter((el, key) => selection.includes(key));

  function sendGls() {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "POST",
      body: JSON.stringify({
        data: dataSelected,
      })
    };
    fetch(config.apiUrl + "order/confirmOrderForBrt.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result !== false) {
          swal({
              title: "Ordine creato!",
              text: "OK",
              icon: "success",
              type: "Success"
            }
          ).then(function() {
            window.location.reload();
          });
        } else {
          swal({
              title: "Ordine non Creato!",
              text: "Controlla l'inserimento corretto",
              icon: "error",
              type: "Error"
            }
          ).then(function() {
            window.location.reload();
          });
        }


      })
      .catch(() => {
        setLoading(false);
      });
  }

  const [columns] = useState([
    { name: "id_order", title: "id Order" },
    { name: "parcell_id", title: "Id parcella" },
    { name: "ref", title: "Referenza" },
  ]);

  const [defaultColumnWidths] = useState([
    { columnName: "id_order", width: 200 },
    { columnName: "parcell_id", width: 200 },
    { columnName: "ref", width: 200 }
  ]);


  return (
    <>
      <div className="container d-flex justify-content-end">
        <div className="row">
          <div className="col">
            <button
              type="submit"
              onClick={sendGls}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              style={{ background: "#2f2d77", color: "#ffffff" }}
            >
              <span>Conferma a BRT</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
         <span className="font-weight-bold">
           Total rows selected:
           {"  "}
           {selection.length}
        </span>
        </div>
      </div>
      <div className="card">
        <Grid
          rows={cazz}
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={15}
          />
          <IntegratedPaging />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <IntegratedSorting />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <Table />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
          />
          <TableHeaderRow />
          <IntegratedSelection />
          <TableSelection
            showSelectAll
          />
          <PagingPanel />
        </Grid>
      </div>
    </>
  );
}
