import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import Container from "react-bootstrap/Container";
// eslint-disable-next-line no-restricted-imports
import Row from "react-bootstrap/Row";
// eslint-disable-next-line no-restricted-imports
import Col from "react-bootstrap/Col";
import config from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  IntegratedPaging, IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState
} from "@devexpress/dx-react-grid";
import swal from "sweetalert";
import { element } from "prop-types";

const initialValues = {
  contatore_progressivo: ""
};

export function MyPage12(props) {
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  let cazz = [];
  const [print_zpl, set_print_zpl] = useState([]);

  useEffect(() => {
    GetCWD();
  }, []);

  const RegistrationSchema = Yup.object().shape({
    contatore_progressivo: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Inserisci Contatore Progressivo Valido!")
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  function GetCWD() {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "GET"
    };

    fetch(config.apiUrl + "order/getcloseWorkDayGls.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading(false);
        } else {
          setLoading(false);
          setDataOrder(result);
        }
      });
  }

  dataOrder.map((element) => {
    return cazz.push(element);
  });

  const [sorting, setSorting] = useState([{ columnName: "product_reference", direction: "asc" }]);
  const [selection, setSelection] = useState([]);
  let dataSelected = dataOrder.filter((el, key) => selection.includes(key));
  const [columns] = useState([

    { name: "nominativo", title: "Nominativo" },
    { name: "contatore_progressivo", title: "Cont Progressivo" },
    { name: "notifica", title: "Notifica" },
    { name: "notaAutista", title: "nota Autista" },
    { name: "indirizzo", title: "Indirizzo" },
    { name: "provincia", title: "Provincia" },
    { name: "city", title: "Città" },
    { name: "zipCode", title: "CAP" },
    { name: "colli", title: "colli" },
    { name: "peso", title: "peso" },
    { name: "volume", title: "volume" },
    { name: "colleType", title: "colleType" },
    { name: "contrassegno", title: "contrassegno" },
    { name: "serAcc", title: "serAcc" },
    { name: "note", title: "note" },
    { name: "notificaTel", title: "notificaTel" },
    { name: "bda", title: "bda" },
    { name: "asssicurazione", title: "assicurazione" },
    { name: "craeted_at", title: "created_at" }
  ]);

  const [defaultColumnWidths] = useState([
    { columnName: "nominativo", width: 120 },
    { columnName: "contatore_progressivo", width: 70 },
    { columnName: "notifica", width: 120 },
    { columnName: "notaAutista", width: 100 },
    { columnName: "indirizzo", width: 120 },
    { columnName: "provincia", width: 120 },
    { columnName: "city", width: 120 },
    { columnName: "zipCode", width: 100 },
    { columnName: "colli", width: 50 },
    { columnName: "peso", width: 50 },
    { columnName: "volume", width: 50 },
    { columnName: "colleType", width: 50 },
    { columnName: "contrassegno", width: 100 },
    { columnName: "serAcc", width: 100 },
    { columnName: "note", width: 100 },
    { columnName: "notificaTel", width: 100 },
    { columnName: "bda", width: 100 },
    { columnName: "asssicurazione", width: 100 },
    { columnName: "craeted_at", width: 200 }
  ]);

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      setLoading(true);
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
        },
        method: "POST",
        body: JSON.stringify({
          contatore_progressivo: values.contatore_progressivo
        })
      };
      fetch(config.apiUrl + "order/ZplGls.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          set_print_zpl(result);

          swal({
              title: "Zpl Creato",
              text: "OK",
              icon: "success",
              type: "Success"
            }
          ).then(function() {
            window.location.reload();
          });
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
        });
    }
  });

  let amore = print_zpl.map((element) => element.zpl);
  function saveTextAsFile(textToWrite, fileNameToSaveAs, fileType) {
    let downloadLink = document.createElement("a");
    if (amore !== []) {
      let textFileAsBlob = new Blob([textToWrite], { type: fileType });
      downloadLink.download = fileNameToSaveAs;
      downloadLink.innerHTML = "Download File";
      downloadLink.href = window.webkitURL.createObjectURL(
        textFileAsBlob
      );
    }
    downloadLink.click();
  }

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            id="input"
            placeholder="Contatore Progressivo"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "contatore_progressivo"
            )}`}
            name="email"
            {...formik.getFieldProps("contatore_progressivo")}
          />
          {formik.touched.contatore_progressivo && formik.errors.contatore_progressivo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.contatore_progressivo}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <div className="btnDiv">
            <button
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              style={{ background: "#2f2d77", color: "#ffffff" }}
              id="downloadBtn"
              value="download"
              onClick={saveTextAsFile(amore, "hello.txt", "application/json")}>
              <span>Zpl</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}</button>
          </div>
        </div>
      </form>
      <div className="container">
        <div className="row">
         <span className="font-weight-bold">
           Total rows selected:
           {"  "}
           {selection.length}
        </span>
        </div>
      </div>
      <div className="card">
        <Grid
          rows={cazz}
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={15}
          />
          <IntegratedPaging />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <IntegratedSorting />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <Table />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
          />
          <TableHeaderRow />
          <IntegratedSelection />
          <TableSelection
            showSelectAll
          />
          <PagingPanel />
        </Grid>
      </div>
    </>
  );
}
